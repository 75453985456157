import React, {useRef} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contact from './pages/Contact';
import Golf from './pages/Golf';
import Grass from './pages/Grass';
import Footer from './components/Footer';
import Header from './components/Header';
import Privacy from './pages/Privacy';
import DecodeParams from './components/Decodeparams';
import PageNotFound from './pages/PageNotFound';
import Testimonials from './pages/Testimonials';
import './styles.css';

function App() {
  const formRef = useRef(null);
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<><Header formRef={formRef} /><Contact formRef={formRef} /><Footer /></>}
        />
        <Route
          path="/prefill/:fill"
          element={<DecodeParams />}
        />
        <Route
          path="/golf"
          element={<><Header formRef={formRef} /><Golf /><Footer /></>}
        />
        <Route
          path="/grass"
          element={<><Header formRef={formRef} /><Grass ren='1' /><Footer /></>}
        />
        <Route
          path="/color"
          element={<><Header formRef={formRef} /><Grass ren='2' /><Footer /></>}
        />
        <Route
          path="/testimonials"
          element={<><Header formRef={formRef} /><Testimonials /><Footer /></>}
        />
        <Route
          path="/privacy-policy"
          element={<><Header formRef={formRef} /><Privacy /><Footer /></>}
        />
        <Route
          path='*'
          element={<PageNotFound />}
        />
      </Routes>
    </Router>
  );
}

export default App;