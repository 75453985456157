import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  validateEmail,
  validatePhoneNumber,
  getKey,
  getEnv
} from '../utils/helpers';
import {
  updateMessage,
  updateStatus,
  updateLead,
  updateCalendly,
  updateCalendlyUrl
} from '../utils/Actions';
import { Overlay } from '../components/Overlay';
import { Audience } from '../components/Audience';
import Technology from '../components/Technology';
import Calltoaction from '../components/Calltoaction';
import Virtualassistant from '../components/Virtualassistant';
import Videotech from '../components/VideoTech';
import Address from '../components/Address';
import Calendly from '../components/Calendly';
import Reviews from '../components/Reviews';
import Portfolio from '../components/Portfolio';
import ReCAPTCHA from 'react-google-recaptcha';
import { MapPinIcon, PhoneIcon } from '@heroicons/react/20/solid';

function Contact({ formRef }) {

  const state = useSelector(state => state);
  const dispatch = useDispatch();

  // this state is used to save validated json data to be sent to the backend
  const [api, setApi] = useState(null);

  // this state is to use google captcha
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaSuccess, setCaptchaSuccess] = useState(false);

  // Verify the CAPTCHA token with the backend
  useEffect(() => {
    const verifyCaptcha = async () => {
      if (captchaToken) {//call backend only if there is a token
        try {
          const response = await fetch('/api/captcha', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: captchaToken }),
          });
          const data = await response.json();
          // Proceed with further action based on the verification response
          setCaptchaSuccess(data.success);
          // reset the captcha after 60 seconds
          setTimeout(setCaptchaSuccess, 60000, false);
        } catch (err) {
          console.error(err);
        }
      };
    };
    verifyCaptcha();
  }, [captchaToken]);


  // this hook will run every time the api state is updated. api state contains validated form data
  useEffect(() => {

    const abc = async () => {
      if (api) { // call backend only if there is data in the state
        try {
          await fetch('/api/lead', { // email the lead data
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': getKey() // this is for protection of the server route
            },
            body: JSON.stringify(api),
          });

          state.lead.audience.ottawa // prepare the correct calendly to run based on user unput
            ?
            dispatch(updateCalendlyUrl(await getEnv('CALOTTAWA')))
            :
            dispatch(updateCalendlyUrl(await getEnv('CALGTA')));

          dispatch(updateCalendly(true)); // display calendly after the email is sent

        } catch (err) {
          console.error(err);
        }
        setApi(null);
      }
    };
    abc();
  }, [api]);

  const handleInputChange = (e) => {
    // Getting the value and name of the input which triggered the change
    const { target } = e;
    const inputType = target.name;
    const inputValue = target.value;

    // Based on the input type, update the state

    switch (inputType) {
      case 'email':
        dispatch(updateLead({ ...state.lead, email: inputValue }));
        break;
      case 'first-name':
        dispatch(updateLead({ ...state.lead, firstName: inputValue }));
        break;
      case 'last-name':
        dispatch(updateLead({ ...state.lead, lastName: inputValue }));
        break;
      case 'country':
        dispatch(updateLead({ ...state.lead, country: inputValue }));
        break;
      case 'street-address':
        dispatch(updateLead({ ...state.lead, streetAddress: inputValue }));
        break;
      case 'city':
        dispatch(updateLead({ ...state.lead, city: inputValue }));
        break;
      case 'region':
        dispatch(updateLead({ ...state.lead, region: inputValue }));
        break;
      case 'postal-code':
        dispatch(updateLead({ ...state.lead, postalCode: inputValue }));
        break;
      case 'phone':
        dispatch(updateLead({ ...state.lead, phone: inputValue }));
        break;
      case 'comment':
        dispatch(updateLead({ ...state.lead, comment: inputValue }));
        break;
      default:
    }
  };
  const handleMoveOut = (e) => {
    // Getting the value and name of the input which triggered the change
    const { target } = e;
    const inputType = target.name;
    const inputValue = target.value;

    // Based on the input type, set the state of either email, username, and password
    (inputValue === '')
      ? dispatch(updateStatus(`Warning: ${inputType} is a required field`))
      : dispatch(updateStatus(''));
  };

  const handleFormSubmit = (e) => {
    // Preventing the default behavior of the form submit (which is to refresh the page)
    e.preventDefault();

    // check to see if the email is not valid or if the userName is empty. If so we set an error message to be displayed on the page.
    if (!validateEmail(state.lead.email) || !validatePhoneNumber(state.lead.phone) || !state.lead.firstName) {
      dispatch(updateMessage('Error', 'Please enter your first name and valid phone and e-mail'));
    } else {
      dispatch(updateMessage('Confirmation', 'Thank you - your request for estimate has been submitted. Please request the estimator availability in their Calendar'));
      setApi(state.lead); // update api state to send the data to the backend  
      dispatch(updateStatus('')); // clear the status message
    }

  };


  const scrolltoForm = (e) => {
    // Preventing the default behavior of the form submit (which is to refresh the page)
    e.preventDefault();
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }

  };

  return (
    <div>
      {state.message.result && <Overlay />}
      {state.calendly
        ?
        (
          <div>
            <Calendly />
          </div>
        )
        :
        (
          <form>
            <Videotech formRef={formRef} />
            <Technology />
            <Portfolio />
            <div className="overflow-hidden rounded-lg bg-gray-50">
              <p className="mt-4 font-bold mx-4 text-center text-3xl sm: text-2xl">
                Curb-Ease® Free Estimate Request
              </p>
              <p className="mt-4 sm:mt-0 sm:ml-6 mx-8 text-center text-sm leading-6 text-gray-600">
                Please fill in the form below to request your free no obligation estimate. 
              </p>
              {/* <p className="mt-4 sm:mt-0 sm:ml-6 mx-8 text-center text-sm leading-6 text-gray-600">
                Please fill in the form below to request your free no obligation estimate. Once you submit the
                form, the calendar will show up to select the best date and time for the estimator to visit.
              </p> */}

              <div className="px-4 py-5 sm:p-6 flex flex-col sm:flex-row">
                {/* Descriptive content before the form */}
                <p className="mt-4 font-bold">
                  Vasily
                </p>

                <div className="flex flex-col sm:flex-row items-center">

                  <img className="sm:w-1/2 flex-shrink-0" src={require('../assets/Vasily.jpeg')} alt="Curb-Ease Newmarket Vasily" />
                  <div ref={formRef} className='mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8' style={{ paddingBottom: '5vw', paddingTop: '5vw' }}>
                    <div className="space-y-12">

                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">


                        <div className="col-span-full">
                          <div className="mt-2">
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              value={state.lead.firstName}
                              onChange={handleInputChange}
                              onBlur={handleMoveOut}
                              autoComplete="given-name"
                              placeholder="Full Name"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:text-sm sm:leading-6 mr-4"
                            />
                          </div>
                        </div>

                        {/* <div className="sm:col-span-3">
                          <div className="mt-2">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              value={state.lead.lastName}
                              onChange={handleInputChange}
                              autoComplete="family-name"
                              placeholder="last name"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div> */}

                        <div className="col-span-full">
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-700 sm:max-w-md">
                              <input
                                type="text"
                                name="phone"
                                id="phone"
                                value={state.lead.phone}
                                onChange={handleInputChange}
                                onBlur={handleMoveOut}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 mr-4"
                                placeholder="Phone Number"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-span-full">
                          <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-700 sm:max-w-md">
                              <input
                                type="text"
                                name="email"
                                id="email"
                                value={state.lead.email}
                                onChange={handleInputChange}
                                onBlur={handleMoveOut}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 mr-4"
                                placeholder="Email Address"
                              />
                            </div>
                          </div>
                        </div>
                        {/* 
                        <div className="sm:col-span-3">
                          <div className="mt-2">
                            <select
                              id="country"
                              value={state.lead.country}
                              onChange={handleInputChange}
                              name="country"
                              autoComplete="country-name"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                              <option>Canada</option>
                            </select>
                          </div>
                        </div> */}
                        <div className="col-span-full">
                          {/* <Address /> */}
                          {/* <div className="mt-2">
                            <input
                              type="text"
                              name="street-address"
                              id="street-address"
                              value={state.lead.streetAddress}
                              onChange={handleInputChange}
                              autoComplete="street-address"
                              placeholder="123 Main St."
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:text-sm sm:leading-6"
                            />
                          </div> */}
                        </div>

                        {/* <div className="sm:col-span-2 sm:col-start-1">
                          <div className="mt-2">
                            <input
                              type="text"
                              name="city"
                              id="city"
                              value={state.lead.city}
                              onChange={handleInputChange}
                              autoComplete="city"
                              placeholder="City"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div> */}

                        {/* <div className="sm:col-span-2">
                          <div className="mt-2">
                            <select
                              type="text"
                              name="region"
                              id="region"
                              value={state.lead.region}
                              onChange={handleInputChange}
                              autoComplete="address-level1"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:text-sm sm:leading-6"
                            >
                              <option></option>
                              <option>AB</option>
                              <option>BC</option>
                              <option>MB</option>
                              <option>NB</option>
                              <option>NL</option>
                              <option>NT</option>
                              <option>NS</option>
                              <option>NU</option>
                              <option>ON</option>
                              <option>PE</option>
                              <option>QC</option>
                              <option>SK</option>
                              <option>YT</option>
                            </select>
                          </div>
                        </div> */}

                        {/* <div className="sm:col-span-2">
                          <div className="mt-2">
                            <input
                              type="text"
                              name="postal-code"
                              id="postal-code"
                              value={state.lead.postalCode}
                              onChange={handleInputChange}
                              autoComplete="postal-code"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div> */}


                      </div>


                    </div>
                    {/*<Audience />*/}

                    {/*<div className="border-b border-gray-900/10 pb-12">*/}

                    {/* <div className="col-span-full">
                      <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                        Want to leave a message?
                      </label>
                      <div className="mt-2">
                        <textarea
                          id="comment"
                          value={state.lead.comment}
                          name="comment"
                          onChange={handleInputChange}
                          rows={3}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:text-sm sm:leading-6"
                          defaultValue={''}
                        />
                      </div>
                      <p className="mt-3 text-sm leading-6 text-gray-600">Write any comments for the estimator.</p>
                    </div> */}



                    {state.status && (
                      <div>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                          {state.status}
                        </p>
                      </div>
                    )}

                    <div className="mt-6 flex items-center justify-end gap-x-6 col-span-full">

                      {/* Show the submit button only when the captcha is passed */}
                      {captchaSuccess && !state.calendly
                        ?
                        (<button
                          type="submit"
                          onClick={handleFormSubmit}
                          className="rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-700"
                        >
                          Submit
                        </button>)
                        :
                        (
                          /* Render the reCAPTCHA component only when it is required */
                          <ReCAPTCHA
                            sitekey={getKey()}
                            onChange={setCaptchaToken}
                          />

                        )
                      }

                    </div>

                  </div>

                </div>

              </div>
            </div>

            <Reviews />
            <Calltoaction formRef={formRef} />
            <Virtualassistant formRef={formRef} />
          </form>
        )}
    </div>

  );
}

export default Contact;