import Quote from '../components/Quote'

const items = [
    { id: 20, testimonial: `Vasily came to our place and installed a curbing to improve the look of our waterfront edge. We were extremely pleased with the outcome. It was installed exactly how we ordered it and in a very timely manner. He was very professional and courteous. We absolutely love the new look. Thank you so much.`, name: 'Shirley Oesterle' },
    { id: 19, testimonial: `Vasily installed continuous concrete curb in my front and back yard last week, and I couldn't be happier with the results! He did an outstanding job, showing great attention to detail and creativity. The design perfectly complements my yard, and the quality of work is top-notch. He was professional, punctual, and truly passionate about his job. I highly recommend him to anyone looking for quality landscaping work. My yard has never looked better—thank you!"`, name: 'H Lahi' },
    { id: 18, testimonial: 'We met Vasily at the Newmarket Home Show. Called him shortly after and have enjoyed having him at our home. His expertise and attention to detail is impressive and the final outcome INCREDIBLE.. We are so pleased that we have already recommended him to our neighbours. While we have a muted earth colour they are having the grey to set off their house.', name: 'Carol Norris' },
    { id: 17, testimonial: 'We live in Manotick and had curb ease come and transform our garden with this beautiful border. They were very knowledgeable  about their product. Very happy how it turned out.', name: 'Mary Boyce' },
    { id: 16, testimonial: 'This past spring we discovered Curb-Ease at a local home show. We requested a quote for curbing along all our planting beds. The owner Vasily attended our home and completed a quote for our property. He also explained in great detail all about the product and its installation. We booked a date and Vasily arrived on time. He completed the entire project in an entire day. Both Vasily and Mike were a pleasure to deal with. They were exceptionally courteous and friendly. The final product was  beautiful and very professionally installed. Vasily returned a few days later to seal the curbing. That enhanced the appearance of the curbing even more. We are very pleased and highly recommend Vasily and his team to install this product.', name: 'John Vandenburg' },
    { id: 15, testimonial: 'Love the look of our new Curb .. and happy to no longer have to weed in between the old paver stones that used to line all of our Gardens!   Installers were pleasant, arrived and finished on time and end result was just what we hoped for.', name: 'Andrew Lee' },
    { id: 14, testimonial: 'Last year I contacted Vasily yo install curbing around my back patio. It was professionlly done and we were vesry pleased with the results . That same year we did  some addional landscaping to to other ide of the yard . I called Vasily this year to come and add some addional curbing to the new landscaping .As was expected he came and gave me a reasonable quote and scheduled in a installation time. The installation was great and this additional curbing plended in beautifully with the exsisting curbing.Vasily teated us and the work he did for us in a very thoughtful way paying attention to detail. I would highly recommend Vasily and Curb-Ease  to anyone interested  in this unique product.', name: 'James Poole' },
    { id: 13, testimonial: 'Vasily is one of the most professional service providers I have met. His visit to the job site was on time and the quote was very reasonable for the work to be done. The final job is very satisfactory. We highly recommend Curb Ease for all you landscaping curbs.', name: 'Edward Tracy Re/Max Hallmark Chay Realty' },
    { id: 12, testimonial: 'We had Vasily come over in the spring and give us an estimate on some edging around the gardens. After receiving the quote, we decided to go ahead. I can not tell you enough how professional Vasily was, before, during and after the installation of our curbs. My husband and I are very pleased with the end results. We have received many compliments from neighbours as well as people in the cars driving by while I am out watering. I would definitely recommend Curb-Ease Newmarket, and actually have already.', name: 'Debbie Conway' },
    { id: 11, testimonial: 'Vasily did a good job with good service on time and beautiful new idea i got for my flower bed which many people interested the idea, thanks 👍', name: 'Rabea Razzaghi' },
    { id: 10, testimonial: 'Vasily did an amazing job.  We are so happy with our gardens at the front of the house.  He was very professional, explained everything to us.  We\'ve had many comments as well.  I would do it again', name: 'Lise Carriere' },
    { id: 9, testimonial: 'From my first call for a quote to the project completion Vasily from Curb Ease was responsive and professional. We were renovating our front yard and Vasily and the people from Curb Ease were very flexible and accommodating with respect to the landscapers timetable. We love the finished product and would highly recommend Vasily and Curb Ease.', name: 'Richard Poulouski' },
    { id: 8, testimonial: 'We could not be happier with the service and work performed by Curb-Ease Newmarket. Vasily is a true professional and takes pride in his workmanship.  He is prompt, courteous and knowledgeable and an overall nice person.  We will definitely  be seeking him out again for our next project and recommend him highly. M Gallion', name: 'Mona G' },
    { id: 7, testimonial: 'Vasily and his team did a superb job designing and installing two relatively small oval garden edge areas. The procedure was promptly and painlessly executed, and the workmanship was first rate. The Curb-Ease product looks classy and bumps up the visual appeal of the front lawn area by a significant amount. Highly recommended!', name: 'Glen Wagenknecht' },
    { id: 6, testimonial: 'Vasily was very professional. He installed my concrete boarder for my garden. It looks amazing!! He cleaned up when he was finished.', name: 'Laura S' },
    { id: 5, testimonial: 'We are extremely happy with service and workmanship we received. It transforms your yard in a matter of a few hours. I have tried plastic, metal, stone edging, but I was never fully happy. With concrete edging you get a continuous edging that looks fabulous.', name: 'Sebastian Wiewiora' },
    { id: 4, testimonial: 'Everything was absolutely first rate.  Vasily and Max were professional and friendly and did everything to ensure the project was done on time and looked amazing. Could not recommend more highly, these guys rock.', name: 'Randall Bergeron' },
    { id: 3, testimonial: 'The product looks good and they are very interactive over email answering questions and planning the job. Vasily is a good guy.', name: 'Mark' },
    { id: 2, testimonial: 'Great communication, fast work but great quality, very impressed', name: 'Kaleb Streeter' },
    { id: 1, testimonial: '5 stars', name: 'Suzanne Ranger' }
]

export default function Testimonials() {
    return (
        <>
            <h1 className="font-semibold text-gray-900 text-center">Curb-Ease® Newmarket Testimonials</h1>
            <ul className="divide-y divide-gray-200">
                {items.map((item) => (
                    <li key={item.id} className="py-4">
                        <Quote testimonial={item.testimonial} name={item.name} />
                    </li>
                ))}
            </ul>
        </>
    )
}